




















import '@bukalapak/bazaar-dweb/dist/components/BlPopoverAv.css'
import { BlSeparatorAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  components: {
    BlTextAv,
    BlSeparatorAv,
  },
  props: {
    articleLinks: {
      type: Array as PropType<{ text: string; target: string }[]>,
      required: true,
    },
    showArticleNavbar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileTablet(): boolean {
      return this.$viewport.isLessThan('desktop').valueOf()
    },
  },
  methods: {
    onClickItem(link: string) {
      this.$router.push(link)
    },
  },
})
