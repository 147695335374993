




















































import '@bukalapak/bazaar-dweb/dist/components/BlPopoverAv.css'
import { BlSheetMv, BlTextAv } from '@bukalapak/bazaar-mweb'
import IcoChevronDownMinor from '@bukalapak/bazaar-visual/dist/components/IcoChevronDownMinor'
import IcoChevronUpMinor from '@bukalapak/bazaar-visual/dist/components/IcoChevronUpMinor'
import Vue from 'vue'
import SectionCategories from '../SectionCategories.vue'
import BlPopoverAv from '~/components/atoms/BlPopoverAv.vue'

export default Vue.extend({
  components: {
    SectionCategories,
    BlTextAv,
    BlPopoverAv,
    BlSheetMv,
    IcoChevronUpMinor,
    IcoChevronDownMinor,
  },
  data() {
    return {
      isPopupPermanentlyShown: false,
      isPopupTemporarilyShown: false,
      isDwebPopupTriggerClickedRecently: false,
      dwebCategoryPopupTimer: 0,
    }
  },
  computed: {
    isMobileTablet(): boolean {
      return this.$viewport.isLessThan('desktop').valueOf()
    },
    isPopupShown(): boolean {
      return this.isPopupPermanentlyShown || this.isPopupTemporarilyShown
    },
  },
  methods: {
    onClickCategory() {
      this.isPopupPermanentlyShown = false
      this.isPopupTemporarilyShown = false
    },
    onEnterDwebCategorySection() {
      clearTimeout(this.dwebCategoryPopupTimer)
      this.dwebCategoryPopupTimer = window.setTimeout(() => {
        this.isPopupTemporarilyShown = true
      }, 200)
    },
    onLeaveDwebCategorySection() {
      clearTimeout(this.dwebCategoryPopupTimer)
      this.isPopupTemporarilyShown = false
    },
    onToggleCategoryPopup(isShown) {
      this.isPopupPermanentlyShown = isShown
    },
  },
})
