
































































































import { BlButtonAv, BlNavBarSearchMv, BlSeparatorAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import ArticleSelector from './ArticleSelector.vue'
import CategorySelector from './CategorySelector.vue'
import BlIcon from '~/components/atoms/BlIcon.vue'
import ArticleNavbar, { ArticleLink } from '~/components/organisms/LandingPage/article/ArticleNavbar.vue'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'

export type LandingHeaderProps = {
  isWhite: boolean
  isStaticHeader: boolean
  screen?: string
}

type Link = {
  text: string
  target: {
    path: string
    hash: string
  }
}

const MOBILE_TOP_OFFSET = 36

export default Vue.extend({
  name: 'LandingHeader',
  components: {
    ArticleSelector,
    CategorySelector,
    BlTextAv,
    BlButtonAv,
    BlSeparatorAv,
    ArticleNavbar,
    BlNavBarSearchMv,
    BlIcon,
  },
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    isStaticHeader: {
      type: Boolean,
      default: false,
    },
    showArticleNavbar: {
      type: Boolean,
      default: false,
    },
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMenuActive: false,
      lastScrollY: 0,
      currentScrollY: 0,
      isHeaderShown: true,
      hasScrolled: false,
      logo: require('~/assets/images/bukabangunan-horizontal-logo.png'),
      keywords: '',
      isSearchArticleActive: false,
    }
  },
  fetch() {
    this.keywords = (this.$route.query.keywords as string) || ''
  },
  computed: {
    isTransparent(): boolean {
      const changePoint = 72
      return !this.isWhite && this.currentScrollY < changePoint
    },
    links(): Link[] {
      const links = [
        {
          text: 'Beranda',
          target: {
            path: '/',
            hash: '',
          },
        },
      ]

      return links
    },
    isMobileTablet(): boolean {
      return this.$viewport.isLessThan('desktop').valueOf()
    },
    isArticleNavbarShown(): boolean {
      return !this.isMobileTablet && this.showArticleNavbar
    },
    articleLinks(): ArticleLink[] {
      return [
        { text: 'Info', target: '/artikel/c/info' },
        { text: 'Inspirasi', target: '/artikel/c/inspirasi' },
        { text: 'Promo', target: '/artikel/c/promo' },
        { text: 'Tips dan Trik', target: '/artikel/c/tips-dan-trik' },
      ]
    },
    b2bPartnerSite(): ArticleLink[] {
      return this.$config?.b2bPartnerSite || 'https://bukabangunan.com/jadi-partner'
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.isMenuActive = false
        this.enableScroll()
      },
    },
  },
  mounted() {
    this.handleScroll()
    if (process.client) {
      window.addEventListener('scroll', this.debounceScroll, { passive: true })
    }
  },
  destroyed() {
    if (process.client) {
      window.removeEventListener('scroll', this.debounceScroll)
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive

      if (this.isMenuActive) {
        this.disableScroll()
        this.trackClick('HamburgerMenu_Button')
      } else {
        this.enableScroll()
      }
    },
    debounceScroll() {
      if (!this.hasScrolled) {
        this.handleScroll()
        this.hasScrolled = true

        setTimeout(() => {
          this.hasScrolled = false
        }, 200)

        setTimeout(() => {
          if (window.scrollY < MOBILE_TOP_OFFSET) {
            this.isHeaderShown = true
          }
        }, 1000)
      }
    },
    handleScroll() {
      this.currentScrollY = window.scrollY
      if (this.isMobileTablet) {
        const mobileTopOffset = 36
        this.isHeaderShown = this.lastScrollY > window.scrollY || window.scrollY < mobileTopOffset
      }
      this.lastScrollY = window.scrollY
    },
    async goTo(target) {
      this.enableScroll()
      if (target.hash) {
        if (target.path !== this.$route.path) {
          await this.$router.push(target)
        }
        const headerDwebHeight = 72
        const headerMwebHeight = 56
        const offset = this.isMobileTablet ? -headerMwebHeight : -headerDwebHeight
        ;(this as any).$scrollTo(target.hash, 500, { offset })
      } else {
        await this.$router.push(target)
      }

      this.isMenuActive = false
    },
    disableScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

      window.onscroll = () => {
        window.scrollTo(scrollLeft, scrollTop)
      }
    },
    enableScroll() {
      window.onscroll = () => {}
    },
    handleDownload() {
      this.$nuxt.$emit(MainGlobalEvents.HEADER_DOWNLOAD_EVENT)
      this.trackClick('Download_Button')
    },
    goToSearch() {
      this.$router.push({ name: 'artikel', query: { keywords: this.keywords } })
    },
    showSearchBar() {
      this.keywords = (this.$route?.query?.keywords as string) || ''
      this.isSearchArticleActive = true
    },
    trackClick(source: string) {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: 'Header_Section',
          action_type: 'click',
          action_source: source,
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
