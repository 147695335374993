





























































import { BlTextAv } from '@bukalapak/bazaar-mweb'
import { PublicCategory } from '@bukalapak/openapigen/products/mitrabangunan/product/v1/public'
import VLazyImage from 'v-lazy-image/v2'
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import stringToSlug from '~/utils/converter/string-to-slug'

const WHITESPACES_PATTERN = /\s+/g

type SectionCategoryItem = {
  id: PublicCategory['id']
  name: PublicCategory['name']
  alt: string
  slug: string
  url: string
}

export default Vue.extend({
  components: {
    BlTextAv,
    VLazyImage,
  },
  props: {
    shownCategoryLimit: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imagePlaceholder: require('~/assets/images/loader-thumbnail.svg'),
      expandTriggerImage: require('~/assets/images/landing-page/category/ic_more_dope.png'),
      notLoadedImages: {} as Record<string, boolean>,
    }
  },
  computed: {
    ...mapState(['publicProductCategories']),
    shownCategories(): SectionCategoryItem[] {
      return this.publicProductCategories.slice(0, this.shownCategoryLimit).map(category => {
        const lowercasedName = `${category.name ?? ''}`.toLowerCase()
        return {
          id: category.id ?? '-',
          name: category.name,
          alt: lowercasedName.replace(WHITESPACES_PATTERN, ' ').trim(),
          slug: category.slug || stringToSlug(lowercasedName),
          url: category.image_url ?? this.imagePlaceholder,
          parentId: category.parent_id,
        }
      })
    },
    isMobileTablet(): boolean {
      return this.$viewport.isLessThan('desktop').valueOf()
    },
  },
  methods: {
    onFailedToLoadImage(url: SectionCategoryItem['url']) {
      this.$set(this.notLoadedImages, url, true)
    },
    onSelectCategory(shownCategory: SectionCategoryItem) {
      this.$emit('selected', shownCategory.id)
    },
    trackClick() {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: 'AvailableProduct_Section',
          action_type: 'click',
          action_source: 'CategoriesIcon_Button',
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
