












import Vue from 'vue'
import { MainGlobalEvents } from '~/constants/pages/main-global-events'

export default Vue.extend({
  name: 'LandingPlayStoreButton',
  props: {
    loading: {
      type: String,
      default: null,
    },
    screen: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick() {
      this.$nuxt.$emit(MainGlobalEvents.HEADER_DOWNLOAD_EVENT)
      this.trackClick()
    },
    trackClick() {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: this.section,
          action_type: 'click',
          action_source: 'GooglePlayStoreIcon_Button',
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
