const WORD_PATTERN_REPLACEMENTS = {
  '&': '-dan-',
  ',': '-dan-',
  '/': '-atau-',
  '\\': '-atau-',
  '.': '-',
  '%': '-persen-',
}
const WORD_PATTERN_MATCHERS = /&|\\|\/|,|\.|%|\s+/gi
const MULTIPLE_DASHED_MATCHERS = /-+/gi

/**
 * Convert any string into slug format (seo friendly format) + escape reserved URI
 *
 * @example
 * TransactionAPI()
 * .download()
 * .then(() => {........}})
 * .catch(errors) => {
 *   errorToString(errors)
 * })
 */
export default (input: string): string => {
  const lowercasedInput = input.toLowerCase().trim()
  const convertedInput = lowercasedInput.replace(WORD_PATTERN_MATCHERS, matched => {
    return WORD_PATTERN_REPLACEMENTS[matched] ?? '-' // if whitespaces are matched
  })
  return encodeURIComponent(convertedInput.replace(MULTIPLE_DASHED_MATCHERS, () => '-'))
}
