

























































































































































































































import { BlFlexContainerMv, BlTextAv } from '@bukalapak/bazaar-mweb'
import Vue from 'vue'
import PlayStoreButton from '~/components/organisms/LandingPage/PlayStoreButton/index.vue'

export default Vue.extend({
  name: 'LandingFooter',
  components: {
    BlFlexContainerMv,
    BlTextAv,
    PlayStoreButton,
  },
  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      links: {
        terms: '/terms-and-conditions',
        policy: '/privacy-policy',
        faq: '/faq',
        deleteAccountGoogleForm: 'https://forms.gle/ufyhHvCaNQ7xqBz86',
        contactUsWhatsapp: 'https://wa.me/6281122334455',
        contactUsPhone: 'tel:6287812000332',
        contactUsEmail: 'mailto:bukabangunan@bukalapak.com',
        consumerComplaintServiceWhatsapp: 'https://wa.me/6285311111010',
        instagram: 'https://www.instagram.com/buka.bangunan/',
        facebook: 'https://www.facebook.com/BukaBangunan-103301312456034',
        tiktok: 'https://www.tiktok.com/@bukabangunan',
        youtube: 'https://www.youtube.com/channel/UCsZsf-pc-jmnSpLDrZDJ8eA',
      },
    }
  },
  methods: {
    trackClick(source: string) {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: 'Footer_Section',
          action_type: 'click',
          action_source: source,
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
