var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-header",class:{
      white: !_vm.isTransparent || _vm.showArticleNavbar,
      active: _vm.isMenuActive,
      'mobile-hidden': !_vm.isHeaderShown && !_vm.isStaticHeader,
      'with-article-navbar': _vm.isArticleNavbarShown,
    },attrs:{"data-testid":"c-header"}},[(_vm.isSearchArticleActive && _vm.showArticleNavbar && _vm.isMobileTablet)?_c('bl-nav-bar-search-mv',{attrs:{"placeholder":"Cari artikel terkait lainnya...","auto-focus":""},on:{"handleClickCancel":function($event){_vm.isSearchArticleActive = false},"enter":_vm.goToSearch},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}}):_c('div',{staticClass:"c-header__navbar"},[_c('div',{staticClass:"c-header__navbar--top-items"},[_c('nuxt-link',{attrs:{"to":"/","aria-label":"BukaBangunan"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"Logo BukaBangunan","width":_vm.isMobileTablet ? '158' : '220',"height":_vm.isMobileTablet ? '20' : '28'}})]),_vm._v(" "),(_vm.isMobileTablet)?[(!_vm.isSearchArticleActive && _vm.showArticleNavbar)?_c('div',{staticClass:"c-header__navbar--search",on:{"click":_vm.showSearchBar}},[_c('bl-icon',{attrs:{"name":"ico_search"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c-header__navbar--expand",attrs:{"data-testid":"c-header-toggle-menu"},on:{"click":_vm.toggleMenu}},[_c('div',{staticClass:"c-header__menu",class:{ white: !_vm.isTransparent, active: _vm.isMenuActive },attrs:{"data-testid":"c-header-menu"}})])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"c-header__navbar--actions",class:{ active: _vm.isMenuActive },attrs:{"data-testid":"c-header-actions"}},[_vm._l((_vm.links.entries()),function(ref){
    var index = ref[0];
    var link = ref[1];
return _c('bl-text-av',{key:("article_link_" + index),staticClass:"c-header__navbar--actions-item",attrs:{"data-test":"header-link","variation":_vm.isMobileTablet ? 'body-16' : 'subheading-18',"color":"inverse","href":link.target.path},nativeOn:{"click":function($event){$event.preventDefault();return _vm.goTo(link.target)}}},[_vm._v("\n          "+_vm._s(link.text)+"\n        ")])}),_vm._v(" "),_c('category-selector',{staticClass:"p-0"}),_vm._v(" "),_c('nuxt-link',{attrs:{"to":{ name: 'artikel' }}},[_c('bl-text-av',{staticClass:"c-header__navbar--actions-item",attrs:{"data-testid":"navbar-action-article","variation":"body-16"}},[_vm._v("\n            Artikel\n          ")])],1),_vm._v(" "),_c('a',{attrs:{"href":_vm.b2bPartnerSite}},[_c('bl-text-av',{staticClass:"c-header__navbar--actions-item",attrs:{"data-testid":"navbar-action-b2b","variation":"body-16"}},[_vm._v("\n            Jadi Partner\n          ")])],1),_vm._v(" "),(_vm.isMobileTablet)?_c('div',{staticClass:"c-header__navbar--actions-separator"},[_c('bl-separator-av')],1):_vm._e(),_vm._v(" "),_c('article-selector',{attrs:{"article-links":_vm.articleLinks,"show-article-navbar":_vm.showArticleNavbar}}),_vm._v(" "),_c('div',{staticClass:"c-header__navbar--actions-footer"},[(_vm.isMobileTablet)?_c('bl-text-av',{attrs:{"variation":"body-16"}},[_vm._v("\n            Yuk, belanja mudah dan murah dengan BukaBangunan!\n          ")]):_vm._e(),_vm._v(" "),_c('bl-button-av',{attrs:{"size":"small","target":"_blank","data-testid":"c-header-download"},on:{"click":_vm.handleDownload}},[_vm._v("\n            Download sekarang\n          ")])],1)],2)]),_vm._v(" "),(_vm.isArticleNavbarShown)?_c('article-navbar',{attrs:{"keywords":_vm.keywords,"article-links":_vm.articleLinks},on:{"update:keywords":function($event){_vm.keywords=$event},"goToSearch":_vm.goToSearch}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }