var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"popoverWrapper",class:_vm.popoverClasses,on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}},[_c('div',{ref:"popoverToggle",on:{"click":_vm.handleToggle}},[_vm._t("default")],2),_vm._v(" "),(_vm.isKeepRendered)?[_c('transition',{attrs:{"name":"fade-dropdown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopover),expression:"showPopover"}],staticClass:"bl-popover__outer",style:({
          marginTop:
            _vm.position === 'bottom' || _vm.position === 'left' || _vm.position === 'right' ? (_vm.customSpace + "px") : undefined,
          marginBottom: _vm.position === 'top' ? (_vm.customSpace + "px") : undefined,
        })},[_c('div',{staticClass:"bl-popover__inner",style:({
            borderRadius: _vm.hasBorderRadius ? "4px" : "0",
            border: _vm.hasSmoothBorder ? "1px solid #ececec" : "1px solid #c0c7d1",
            boxShadow: _vm.hasSmoothBorder ? "0 1px 5px 0 rgba(0, 0, 0, 0.12)" : "0 2px 4px 0 rgba(0, 0, 0, 0.12)",
          })},[(_vm.$slots.header)?_c('div',{staticClass:"bl-popover__header"},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_vm._t("content"),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"bl-popover__footer"},[_vm._t("footer")],2):_vm._e()],2)])])]:[_c('transition',{attrs:{"name":"fade-dropdown"}},[(_vm.showPopover)?_c('div',{staticClass:"bl-popover__outer",style:({
          marginTop:
            _vm.position === 'bottom' || _vm.position === 'left' || _vm.position === 'right' ? (_vm.customSpace + "px") : undefined,
          marginBottom: _vm.position === 'top' ? (_vm.customSpace + "px") : undefined,
        })},[_c('div',{staticClass:"bl-popover__inner",style:({
            borderRadius: _vm.hasBorderRadius ? "4px" : "0",
            border: _vm.hasSmoothBorder ? "1px solid #ececec" : "1px solid #c0c7d1",
            boxShadow: _vm.hasSmoothBorder ? "0 1px 5px 0 rgba(0, 0, 0, 0.12)" : "0 2px 4px 0 rgba(0, 0, 0, 0.12)",
          })},[(_vm.$slots.header)?_c('div',{staticClass:"bl-popover__header"},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_vm._t("content"),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"bl-popover__footer"},[_vm._t("footer")],2):_vm._e()],2)]):_vm._e()])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }