



















































import { BlModalMv } from '@bukalapak/bazaar-dweb'
import { BlFlexContainerMv, BlFlexItemAv, BlTextAv, BlTooltipMv } from '@bukalapak/bazaar-mweb'
import { IcoDuplicate } from '@bukalapak/bazaar-visual'
import copy from 'copy-to-clipboard'
import Vue from 'vue'
import { playStoreLink } from '~/constants/LandingPage'

export default Vue.extend({
  name: 'LandingDownloadModal',
  components: {
    IcoDuplicate,
    BlModalMv,
    BlTextAv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlTooltipMv,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSuccessCopy: false,
      downloadLink: playStoreLink,
    }
  },
  computed: {
    isActive: {
      get(): boolean {
        return this.active
      },
      set(newValue: boolean) {
        this.$emit('update:active', newValue)
      },
    },
  },
  watch: {
    isActive(active) {
      if (!active) {
        this.trackClickModalClose()
      }
    },
  },
  methods: {
    copyLink(link: string) {
      if (!this.showSuccessCopy) {
        copy(link)
        this.showSuccessCopy = true
        setTimeout(() => {
          this.showSuccessCopy = false
        }, 3000)
        if (this.screen) {
          this.$tracker.track('app_action', {
            screen_name: this.screen,
            section_name: 'DownloadApp_Popup',
            action_type: 'click',
            action_source: 'CopyLink_Hyperlink',
            action_attribute: JSON.stringify({}),
          })
        }
      }
    },
    trackClickModalClose() {
      if (this.screen) {
        this.$tracker.track('app_action', {
          screen_name: this.screen,
          section_name: 'DownloadApp_Popup',
          action_type: 'click',
          action_source: 'Close_Button',
          action_attribute: JSON.stringify({}),
        })
      }
    },
  },
})
