

























import { BlTextfieldAv } from '@bukalapak/bazaar-mweb'
import Vue, { PropType } from 'vue'
import BlIcon from '~/components/atoms/BlIcon.vue'
export type ArticleLink = {
  text: string
  target: string
}
export default Vue.extend({
  name: 'ArticleNavbar',
  components: {
    BlTextfieldAv,
    BlIcon,
  },
  props: {
    keywords: {
      type: String,
      required: true,
    },
    articleLinks: {
      type: Array as PropType<{ text: string; target: string }[]>,
      required: true,
    },
  },
  methods: {
    onClickLink(link: string) {
      this.$router.push(link)
    },
  },
})
