




import Vue from 'vue'

export default Vue.extend({
  name: 'BlIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#2E2E2E',
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconObject(): object {
      const iconNameSnakeCase = this.name
        .split('_')
        .map(substr => substr.charAt(0).toUpperCase() + substr.slice(1))
        .join('')

      return () =>
        import(
          /* webpackChunkName: 'bazaar-icons' */
          /* webpackMode: 'eager' */
          `@bukalapak/bazaar-visual/dist/components/${iconNameSnakeCase}`
        )
    },
  },
})
