
















































































import Vue from 'vue'

// TODO: This component is duplication from original BlPopoverAv. Copied because the original cannot toggle popover programatically
// TODO: Please implement this into bazaar.....
export default Vue.extend({
  name: 'BlPopoverAv',
  props: {
    /**
     * The trigger of the Popover.
     * `click, hover`
     */
    trigger: {
      type: String,
      default: 'click',
    },
    /**
     * The position of the Popover.
     * `bottom, top, left, right`
     */
    position: {
      type: String,
      default: 'bottom',
    },
    /**
     * Use for delaying time to show Popover only when Trigger is Hover
     */
    delay: {
      type: Number,
      default: 0,
    },
    /**
     * If True, will show Popover at first
     */
    isShow: {
      type: Boolean,
      default: false,
    },
    /**
     * The dark background of the Popover.
     */
    dark: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom space between Popover and the Trigger
     */
    customSpace: {
      type: Number,
      default: 4,
    },
    /**
     * Whether Popover has border radius or not
     */
    hasBorderRadius: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether Popover's border color is #d6d6d6 or #ececec
     */
    hasSmoothBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * If True, the popover is always rendered regardless of initial condition, with CSS-based toggling.
     */
    isKeepRendered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPopover: this.isShow || false,
      hoverState: false,
      timer: 0 as number,
    }
  },
  computed: {
    popoverClasses() {
      const mainClass = 'bl-popover'
      const classes = [mainClass]

      Object.keys(this.$props).forEach(propName => {
        const propValue = this.$props[propName]

        if (propValue) {
          if (propName === 'dark') {
            classes.push(`${mainClass}--${propName}`)
          } else if (propName === 'position') {
            classes.push(`${mainClass}--${propValue}`)
          }
        }
      })

      if (this.showPopover) {
        classes.push(`${mainClass}--active`)
      }

      return classes
    },
  },
  watch: {
    isShow(value) {
      this.showPopover = value
    },
  },
  mounted() {
    if (this.trigger === 'click') {
      document.addEventListener('click', this.closePopover)
    }
  },
  destroyed() {
    if (this.trigger === 'click') {
      document.removeEventListener('click', this.closePopover)
    }
  },
  methods: {
    handleMouseOver() {
      if (this.trigger === 'hover') {
        clearTimeout(this.timer)
        this.timer = window.setTimeout(() => {
          this.$emit('update:isShow', true)
        }, this.delay)
      }
    },
    handleMouseLeave() {
      if (this.trigger === 'hover') {
        clearTimeout(this.timer)
        this.$emit('update:isShow', false)
      }
    },
    handleToggle() {
      if (this.trigger === 'click') {
        this.$emit('update:isShow', true)
      }
    },
    closePopover(e) {
      const wrapperEl = this.$refs?.popoverWrapper as Element | undefined
      if (!wrapperEl) return
      if (!wrapperEl.contains(e.target)) {
        this.$emit('update:isShow', false)
      }
    },
  },
})
